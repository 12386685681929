.custom-button {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.circle {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.number-circle {
  font-size: 12px;
  font-weight: bold;
  color: white;
}
