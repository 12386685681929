$breakpoints: ( 'base': 'screen and (max-width: 430px)', 'sp': 'screen and (min-width: 390px) and (max-width: 480px)', 'tab': 'screen and (min-width: 481px) and (max-width: 768px)', 'pc': 'screen and (min-width: 769px) and (max-width: 1024px)', 'pc-large': 'screen and (min-width: 1025px) and (max-width: 1200px)', 'pc-extra-large': 'screen and (min-width: 1201px)', ) !default;
@mixin media($breakpoint) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

//base
@mixin base {
    @include media('base') {
        @content;
        width: 100%;
        height: 100vh;
    }
}

// SP
@mixin sp {
    @include media('sp') {
        @content;
        width: 100%;
        height: 100vh;
    }
}

// TAB
@mixin tab {
    @include media('tab') {
        @content;
        max-width: 100%;
        height: 100vh;
    }
}

// PC 
@mixin pc {
    @include media('pc') {
        @content;
        max-width: 100%;
        height: 100vh;
    }
}

// PC大
@mixin pc-large {
    @include media('pc-large') {
        @content;
        max-width: 100%;
        height: 100vh;
    }
}

// PC特大
@mixin pc-extra-large {
    @include media('pc-extra-large') {
        @content;
        max-width: 1024px;
        height: 100vh;
    }
}

//base
@mixin base-alt {
    @include media('base') {
        @content;
        width: 100%;
    }
}

// SP
@mixin sp-alt {
    @include media('sp') {
        @content;
        width: 100%;
    }
}

// TAB
@mixin tab-alt {
    @include media('tab') {
        @content;
        max-width: 100%;
    }
}

// PC 
@mixin pc-alt {
    @include media('pc') {
        @content;
        max-width: 100%;
    }
}

// PC大
@mixin pc-large-alt {
    @include media('pc-large') {
        @content;
        max-width: 100%;
    }
}

// PC特大
@mixin pc-extra-large-alt {
    @include media('pc-extra-large') {
        @content;
        max-width: 1024px;
    }
}

//ひらがな・カタカナ
//base
@mixin base-grid-container {
    @include media('base') {
        @content;
        max-width: 500px;
    }
}

// SP
@mixin sp-grid-container {
    @include media('sp') {
        @content;
        max-width: 500px;
    }
}

// TAB
@mixin tab-grid-container {
    @include media('tab') {
        @content;
        max-width: 800px;
    }
}

// PC 
@mixin pc-grid-container {
    @include media('pc') {
        @content;
        max-width: 800px;
    }
}

// PC大
@mixin pc-large-grid-container {
    @include media('pc-large') {
        @content;
        max-width: 800px;
    }
}

// PC特大
@mixin pc-extra-large-grid-container {
    @include media('pc-extra-large') {
        @content;
        max-width: 800px;
    }
}

//base
@mixin base-grid-container-alt {
    @include media('base') {
        @content;
        max-width: 300px;
    }
}

// SP
@mixin sp-grid-container-alt {
    @include media('sp') {
        @content;
        max-width: 300px;
    }
}

// TAB
@mixin tab-grid-container-alt {
    @include media('tab') {
        @content;
        max-width: 800px;
    }
}

// PC 
@mixin pc-grid-container-alt {
    @include media('pc') {
        @content;
        max-width: 800px;
    }
}

// PC大
@mixin pc-large-grid-container-alt {
    @include media('pc-large') {
        @content;
        max-width: 800px;
    }
}

// PC特大
@mixin pc-extra-large-grid-container-alt {
    @include media('pc-extra-large') {
        @content;
        max-width: 800px;
    }
}

//base
@mixin base-grid {
    @include media('base') {
        @content;
        padding: 13px;
        min-width: 30px;
    }
}

// SP
@mixin sp-grid {
    @include media('sp') {
        @content;
        padding: 15px;
        min-width: 30px;
    }
}

// TAB
@mixin tab-grid {
    @include media('tab') {
        @content;
        padding: 15px;
        min-width: 50px;
    }
}

// PC 
@mixin pc-grid {
    @include media('pc') {
        @content;
        padding: 15px;
        min-width: 50px;
    }
}

// PC大
@mixin pc-large-grid {
    @include media('pc-large') {
        @content;
        padding: 15px;
        min-width: 50px;
    }
}

// PC特大
@mixin pc-extra-large-grid {
    @include media('pc-extra-large') {
        @content;
        padding: 10px;
        min-width: 50px;
    }
}

//base
@mixin base-grid-alt {
    @include media('base') {
        @content;
        padding: 10px;
        min-width: 30px;
    }
}

// SP
@mixin sp-grid-alt {
    @include media('sp') {
        @content;
        padding: 15px;
        min-width: 30px;
    }
}

// TAB
@mixin tab-grid-alt {
    @include media('tab') {
        @content;
        padding: 10px;
        min-width: 50px;
    }
}

// PC 
@mixin pc-grid-alt {
    @include media('pc') {
        @content;
        padding: 10px;
        min-width: 50px;
    }
}

// PC大
@mixin pc-large-grid-alt {
    @include media('pc-large') {
        @content;
        padding: 10px;
        min-width: 50px;
    }
}

// PC特大
@mixin pc-extra-large-grid-alt {
    @include media('pc-extra-large') {
        @content;
        padding: 10px;
        min-width: 50px;
    }
}