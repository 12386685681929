@import '../../assets/scss/mixin/media-query';

.answerButtonNumberNoselect {
    width: 20px;
    height: 49px;
    line-height: 48px;
    font-size: small;
    font-weight: 700;
    background-color: #f9f9f9;
    border-start-start-radius: 5px;
    border-end-start-radius: 5px;
}

.custom-stack {
  //base
  @include base {
  }

  //SP
  @include sp {
  }
  
  //TAB
  @include tab {
  }
  
  //PC
  @include pc {
  }
  
  //PC大
  @include pc-large {
  }
  
  //PC特大
  @include pc-extra-large {
  }
}

