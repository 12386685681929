.btn-border {
  border-top: 2px solid black;
  border-right: 2px solid black;
  border-left: 2px solid black;
  border-bottom:4px solid black;
}

/* checkbox */
.css-aq8gvp {
  border: 2px black;
}

.amplify-tabs {
  display: none !important;
}
@media only screen and (min-width: 360px){
  #bg-image{
    height: 740px;
    width: 360px;
  }
  #lang_dw{
    max-width: 350px;
  }
}

@media only screen and (min-width: 375px){
  #bg-imaget{
    height: 568px;
    width: 320px;
  }
  #lang_dw{
    max-width: 362px;
  }
}

@media only screen and (min-width: 280px){
  #bg-image{
    height: 653px;
    width: 260px;
  }
  #logo-image{
    height: 262px;
    width: 260px;
  }
  #signin_btn{
    width: 200px;
  }
  #single_btn{
    width: 200px;
  }
  #start_btn{
    width: 200px;
  }
  #lang_dw{
    max-width: 250px;
  }
}

@media only screen and (min-width: 320px){
  #bg-image{
    height: 568px;
    width: 320px;
  }
  #lang_dw{
    max-width: 310px;
  }
}